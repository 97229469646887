import { BasicPagination, PaginationDropdown } from "@banklampung/components";
import { useEffectObj } from "@banklampung/hooks";
import _ from "@banklampung/libs/lodash";
import { notEmpty } from "@banklampung/helper";
import { CInput } from "@coreui/react";
import React, { useState, useMemo } from "react";
import { usePagination, useTable } from "react-table";
import { useDebounce } from "react-use";
import { CSVLink } from "react-csv";
import PropTypes from "prop-types";

const BasicTable = ({
  initialColumns,
  initialData,
  initialPageCount,
  initialParam,
  initialPageSizes,
  loading,
  fetchData,
  searchPlaceholder,
  reload,
  searchRegex,
  showExport,
  heightExport,
  showSearch,
  showFooter,
  slotHeaderRight,
  fileNameExport,
  positionPageSize="TOP"
}) => {
  const [searchVal, setSearchVal] = useState(null);
  const listData = useMemo(() => {
    return initialData.map((obj, idx) => {
      const newBody = _.clone(obj);
      _.each(obj, (val, key) => {
        if (typeof val === "object") {
          newBody[key] = val.cellValue || val.label || val;
        } else {
          newBody[key] = val;
        }
      });
      return newBody;
    });
  });
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    prepareRow,
    page,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns: initialColumns,
      data: initialData,
      initialState: { pageIndex: 0, pageSize: initialPageSizes[0] },
      manualPagination: true,
      pageCount: initialPageCount,
    },
    usePagination
  );

  if (initialColumns[initialColumns.length - 1]["Header"] == "Action" || initialColumns[initialColumns.length - 1]["Header"] == "Aksi") {
    initialColumns.splice(initialColumns.length - 1);
  }

  const csvHeader = useMemo(
    () =>
      initialColumns.map((val) => ({
        label: val.Header,
        key: val.accessor,
      })),
    []
  );

  useEffectObj(() => {
    fetchData({ pageIndex, pageSize, initialParam });
  }, [
    {
      pageIndex: pageIndex,
      pageSize: pageSize,
      reload: reload,
      ...initialParam,
    },
  ]);

  useDebounce(
    () => {
      if (searchRegex && searchVal !== null) {
        let { search, ...other } = initialParam;
        search = _.isEmpty(search) ? "" : search;

        const param = {
          search: _.isEmpty(searchVal)
            ? search
            : _.isEmpty(search) || search.length === 0
            ? searchRegex.replaceAll("val", searchVal)
            : `${search} AND ${searchRegex.replaceAll("val", searchVal)}`,
          ...other,
        };

        fetchData({ pageIndex, pageSize, initialParam: param });
      }
    },
    500,
    [searchVal]
  );

  const onChangeSearch = (value) => {
    setSearchVal(value.replaceAll(" ", "+"));
  };

  return (
    <>
      <div className="d-flex flex-row justify-content-between mb-2">
        { (initialPageSizes.length != 0 && positionPageSize=="TOP") && (
          <PaginationDropdown
            pages={initialPageSizes}
            pageSize={pageSize}
            onChange={(value) => {
              setPageSize(value);
            }}
          />
        )}
        <div className="mr-auto ml-2">{slotHeaderRight}</div>
        {showExport && (
          <div>
            <CSVLink
              className={`btn btn-dark ${heightExport}`}
              filename={`${fileNameExport}.csv`}
              separator={";"}
              headers={csvHeader}
              data={listData}>
              Download CSV
            </CSVLink>
          </div>
        )}
        {showSearch && (
          <div>
            <CInput
              type="input"
              name="search"
              placeholder={searchPlaceholder || "Cari di sini..."}
              onChange={(e) => onChangeSearch(e.target.value)}
            />
          </div>
        )}
      </div>

      <div className="table-responsive">
        <table
          className="table table-hover table-bordered"
          {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps()}
                    className="text-center align-middle">
                    <div
                      className="text-center"
                      style={{
                        minWidth: column.width,
                      }}>
                      {column.render("Header")}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {loading ? (
              <tr>
                <td colSpan="100%" className="text-center">
                  Sedang memuat data...
                </td>
              </tr>
            ) : (
              <>
                {(notEmpty(page)) ? (
                  page.map((row, i) => {
                    prepareRow(row);
                    return (
                      <tr {...row.getRowProps()}>
                        {row.cells.map((cell) => {
                          return (
                            <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                          );
                        })}
                      </tr>
                    );
                  })
                ):(
                  <tr>
                    <td colSpan="100%" className="text-center">
                      Data tidak tersedia
                    </td>
                  </tr>
                )}
              </>
            )}

            
          </tbody>
          
          {showFooter && (
            <tfoot>
              {footerGroups.map((group) => (
                <tr {...group.getFooterGroupProps()}>
                  {group.headers.map((column) => (
                    <th
                      {...column.getFooterProps()}
                      className="text-center align-middle">
                      {column.render("Footer")}
                    </th>
                  ))}
                </tr>
              ))}
            </tfoot>
          )}
        </table>
      </div>

      <div className="d-flex flex-row justify-content-between mb-2">
        <div>
          { (initialPageSizes.length != 0 && positionPageSize=="BOTTOM") && (
            <PaginationDropdown
              pages={initialPageSizes}
              pageSize={pageSize}
              onChange={(value) => {
                setPageSize(value);
              }}
            />
          )}
        </div>

        {!loading && pageCount > 0 && (
          <BasicPagination
            pageIndex={pageIndex + 1}
            pageSize={pageSize}
            pageCount={pageCount}
            onActivePageChange={(value) => {
              if (value !== 0) {
                const valX = value ? Number(value) - 1 : 0;

                gotoPage(valX);
              }
            }}
          />
        )}
      </div>
    </>
  );
};

BasicTable.defaultProps = {
  showExport: false,
  heightExport: "btn-sm",
  fileNameExport: "export",
  initialParam: {},
  initialPageSizes: [10, 25, 50, 100, 500, 1000],
  initialData: [],
};

BasicTable.propTypes = {
  initialData: PropTypes.array,
};

export default BasicTable;
