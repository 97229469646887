const rangeYear = (start, stop, step) =>
  Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + i * step);

class DataHelper {
  static getYearOpts = (options) => {
    const currentYear = new Date().getFullYear();

    const { lengthYear } = options;
    return rangeYear(currentYear, currentYear - (lengthYear || 10), -1).map(
      (val) => {
        return {
          value: val,
          label: `${val}`,
          current: val === currentYear,
        };
      }
    );
  };

  static getAccountTypeOpts = () => [
    {
      value: "INDIVIDUAL",
      label: "Individual",
    },
    {
      value: "LEDGER",
      label: "Buku Besar",
    },
  ];

  static getFundTypesOpts = () => [
    {
      value: "SAVING",
      label: "Saving Account",
    },
    {
      value: "CHECKING",
      label: "Current Account",
    },
    {
      value: "LOAN",
      label: "Loan Account",
    },
    {
      value: "CREDIT_CARD",
      label: "Credit Card Account",
    },
    {
      value: "INVESTMENT",
      label: "Investment Account",
    },
    {
      value: "UNIVERSAL",
      label: "Universal Account",
    },
    {
      value: "DEFAULT",
      label: "Default Account",
    },
  ];

  static getActives = () => [
    {
      label: "Active",
      value: true,
    },
    {
      label: "Inactive",
      value: false,
    },
  ];

  static getYesOrNoOpts = () => [
    {
      label: "Ya",
      value: true,
    },
    {
      label: "Tidak",
      value: false,
    },
  ];

  static deliveryChannelOpts = () =>
    [
      {
        value: "TELLER",
        tag: "6010",
      },
      {
        value: "TREMAR",
        tag: "6010",
      },
      {
        value: "ATM",
        tag: "6011",
      },
      {
        value: "POS EDC",
        tag: "6012",
      },
      {
        value: "AUTO DEBET",
        tag: "6013",
      },
      {
        value: "INTERNET BANKING",
        tag: "6014",
      },
      {
        value: "KIOSK",
        tag: "6015",
      },
      {
        value: "PHONE BANKING",
        tag: "6016",
      },
      {
        value: "MOBILE BANKING",
        tag: "6017",
      },
      {
        value: "AUTO DEPOSIT",
        tag: "6018",
      },
      {
        value: "SMS BANKING",
        tag: "6019",
      },
      {
        value: "LAKU PANDAI",
        tag: "6020",
      },
      {
        value: "ESAMSAT MBA",
        tag: "6020",
      },
      {
        value: "ESAMSAT VASCOMM",
        tag: "6020",
      },
      {
        value: "PJKD VASCOMM",
        tag: "6020",
      },
      {
        value: "QRIS",
        tag: "6100",
      },
      {
        value: "VIRTUAL ACCOUNT",
        tag: "6101",
      },
      {
        value: "OVO",
        tag: "6110",
      },
      {
        value: "GOPAY",
        tag: "6111",
      },
      {
        value: "DANA",
        tag: "6112",
      },
      {
        value: "LINKAJA",
        tag: "6113",
      },
      {
        value: "SHOPEEPAY",
        tag: "6114",
      },
      {
        value: "PAYTREN",
        tag: "6115",
      },
      {
        value: "DOKU",
        tag: "6116",
      },
      {
        value: "INDOMARET",
        tag: "6120",
      },
      {
        value: "ALFAMART",
        tag: "6121",
      },
      {
        value: "TOKOPEDIA",
        tag: "6130",
      },
      {
        value: "SHOPEE",
        tag: "6131",
      },
      {
        value: "BUKALAPAK",
        tag: "6132",
      },
      {
        value: "LAZADA",
        tag: "6133",
      },
      {
        value: "BLIBLI",
        tag: "6134",
      },
      {
        value: "BHINNEKA",
        tag: "6135",
      },
      {
        value: "TRAVELOKA",
        tag: "6137",
      },
      {
        value: "INTERBANK",
        tag: "6138",
      },
      {
        value: "POSINDONESIA",
        tag: "6139",
      },
      {
        value: "PEGIPEGI",
        tag: "6138",
      },
      {
        value: "KPB",
        tag: "7011",
      },
      {
        value: "SIMDA",
        tag: "7012",
      },
      {
        value: "BIFAST",
        tag: "7013",
      },
      {
        value: "KEMENDAGRI",
        tag: "8010",
      },
      {
        value: "KEMENDIKBUD",
        tag: "8011",
      },
      {
        value: "AMS",
        tag: "9010",
      },
      {
        value: "BILLER",
        tag: "9011",
      },
    ].map((obj) => ({
      ...obj,
      label: obj.value,
    }));

  static switchingStatusOpts = (value) => {
    switch (value) {
      case "SIGN_ON":
        return {
          label: "Sign On",
          value: "SIGN_ON",
          color: "success",
        };

      case "SIGN_OFF":
        return {
          label: "Sign Off",
          value: "SIGN_OFF",
          color: "danger",
        };

      default:
        return {
          label: "Error",
          value: "ERROR",
          color: "dark",
        };
    }
  };

  static listMonth = (text) => {
		switch (text) {
			case 1: { return "Januari" }
			case 2: { return "Februari" }
			case 3: { return "Maret" }
			case 4: { return "April" }
			case 5: { return "Mei" }
			case 6: { return "Juni" }
			case 7: { return "Juli" }
			case 8: { return "Agustus" }
			case 9: { return "September" }
			case 10: { return "Oktober" }
			case 11: { return "November" }
			case 12: { return "Desember" }
			default: { return `-` }
		}
	}

    static getMonth = () => [
        {
            value: 1,
            label: "Januari",
        },
        {
            value: 2,
            label: "Februari",
        },
        {
            value: 3,
            label: "Maret"
        },
        {
            value: 4,
            label: "April"
        },
        {
            value: 5,
            label: "Mei"
        },
        {
            value: 6,
            label: "Juni",
        },
        {
            value: 7,
            label: "Juli",
        },
        {
            value: 8,
            label: "Agustus"
        },
        {
            value: 9,
            label: "September"
        },
        {
            value: 10,
            label: "Oktober"
        },
        {
            value: 11,
            label: "November"
        },
        {
            value: 12,
            label: "Desember"
        }
    ];

    static getOverbookingTypeOpts = () => [
      {
        label: "Transfer In-House",
        value: "INTRABANK",
      },
      {
        label: "Transfer Online",
        value: "INTERBANK_ONLINE",
      },
      {
        label: "Transfer BI-FAST",
        value: "INTERBANK_BIFAST",
      },
      {
        label: "Transfer RTGS",
        value: "INTERBANK_RTGS",
      },
      {
        label: "Transfer Bank Lain",
        value: "INTERBANK_INTERMEDIARY",
      }
    ];
}

export default DataHelper;
