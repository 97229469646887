class Storage {
  set = (key, value) => {
    localStorage.setItem(key, JSON.stringify(value));
  };

  remove = (key) => {
    localStorage.removeItem(key);
  };

  get = (key) => {
    return localStorage.getItem(key);
  };

  getObj = (key) => {
    return JSON.parse(this.get(key));
  };

  sets = (items) => {
    items.forEach((elm) => {
      this.set(elm.id, elm.value);
    });
  };
}

const instance = new Storage();

export default instance;
