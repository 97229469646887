import React, { useState, useEffect } from "react";
import { FieldGroup, FieldAgreement } from "@banklampung/components";
import { CRow } from '@coreui/react';

const FieldApproval = ({ form, handleChange, setForm }) => {

    const [randomCode, setRandomCode] = useState("");
    
    useEffect(() => {
        let result = '';
        const characters = 'ABCDEFGHJKLMNPQRSTUVWXYZ123456789';
        const charactersLength = characters.length;
        let counter = 0;
        while (counter < 7) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
            counter += 1;
        }

        setRandomCode(result);
        setForm({
            ...form,
            randomCode: result
        });
    }, []);

    return (
        <>
            <div className="d-flex justify-content-center">
                <CRow className="justify-content-center approval-content">
                    <p className="form-control-static approval-code-txt">
                        {randomCode}
                    </p>
                </CRow>
            </div>

            <FieldGroup
                id="approvalCode"
                label="Verification Code"
                type="input"
                fieldType="password"
                isRequired={true}
                validate={`["required"]`}
                invalid={form.invalids.approvalCode}
                errorMessage={form.errorMessages.approvalCode}
                onChange={handleChange}
                value={form.approvalCode}
            />

            <FieldAgreement form={form} handleChange={handleChange} />
        </>
    );
};

export default FieldApproval;
