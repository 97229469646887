import { hasAuthority } from "@banklampung/helper";
import React, { useMemo } from "react";
import { Alert } from "@banklampung/components";
import { useSelector } from "react-redux";

const Authorization = ({ permissions, rolesPermissions, isAuthorized, children, alert=false }) => {
  const { roleAuthorities, permissionAuthorities } = useSelector(({ auth }) => ({
    roleAuthorities: auth.roles,
    permissionAuthorities: auth.authorities,
  }));

  const checkPermission = () => {
    if(rolesPermissions.length > 0 ){
      
      return hasAuthority(rolesPermissions, roleAuthorities);
    }else {
      
      return hasAuthority(permissions, permissionAuthorities);
    }
  }

  const isValid = useMemo(() =>
    isAuthorized ? isAuthorized :  checkPermission()
  );

  return isValid ? children : (
    <>
      {alert === true && (
        <Alert
          color="danger"
          title="Forbiden"
          content="User anda tidak di izinkan untuk akses menu ini."
        />
      )}
    </>
  );  
};

Authorization.defaultProps = {
  isAuthorized: false,
  rolesPermissions: [],
  permissions:[]
};

export default Authorization;
