import React from "react";
import { notEmpty } from "@banklampung/helper";
import { StaticCardFormClean } from "@banklampung/components";
import { history } from "@banklampung/utils";
import { CNav, CNavLink, CNavItem } from "@coreui/react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import _ from "@banklampung/libs/lodash";
import "./style.scss";

const BasicHorTab = (props) => {
    const { formName, routes, match, children, headerActions, labelName } = props;

    const getUrl = (tabType) => {
        const params = match.params;
        let path = match.path;

        _.forEach(params, function (value, key) {
            path = path.replaceAll(`:${key}`, key === "tabType" ? tabType : value);
        });

        return path;
    };

    return (
        <>
            {notEmpty(formName) && (
                <>
                    <strong style={{fontSize: "x-large"}}>{`${formName}`}</strong>
                    <hr />
                </>
            )}
            
            <StaticCardFormClean
                headerActions={headerActions}
                labelName={labelName}
                body={
                    <>
                        {children}
                        <Router history={history}>
                            <CNav variant="tabs" layout="fill" className="mb-3">
                                {routes.map((route) => (
                                    <CNavItem>
                                        <CNavLink
                                            id={`nav-${route.key}`}
                                            key={route.key}
                                            to={getUrl(route.key)}
                                            className="horizontal-tab"
                                        >
                                        {route.name}
                                        </CNavLink>
                                    </CNavItem>
                                ))}
                            </CNav>
                            <Switch>
                                {routes.map((route, key) => (
                                    <Route
                                        key={key}
                                        path={getUrl(route.key)}
                                        exact={route.exact}
                                        name={route.name}
                                        render={(props) => (
                                            <route.component
                                                navigationTitle={route.name}
                                                navigationKey={route.key}
                                                {...route.propsWrapper}
                                                {...props}
                                                {...route.props}
                                            />
                                        )}
                                    />
                                ))}
                            </Switch>
                        </Router>
                    </>
                }
            />
        </>
    );
};

export default BasicHorTab;
