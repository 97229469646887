import { jwtService } from "@banklampung/services";
import { history } from "@banklampung/utils";
import { AppUtils } from "@app-util";

export const SET_USER_DATA = "[USER] SET DATA";
export const USER_LOGGED_OUT = "[USER] USER LOGGED OUT";

export const loginSuccess = (payload) => {
  jwtService.setSession(payload.accessToken);
};

export const logout = () => {
  jwtService.logout();
};

export const setUserData = (payload) => {
  return (dispatch) => {
    dispatch({
      type: SET_USER_DATA,
      payload: payload,
    });
  };
};

export const logoutUser = (redirectUrl) => {
  return (dispatch) => {
    jwtService.logout();

    if(AppUtils){
      AppUtils.customLogotAction();
    }

    history.push({
      pathname: `/login`,
      search: `${redirectUrl ? `?redirect_path=${redirectUrl}` : ""}`
    });

    dispatch({ type: USER_LOGGED_OUT });
  };
};
