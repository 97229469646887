export const BLPG_MERCHANT                 = "BLPG_Merchant";
export const BLPG_ACCOUNT                  = "BLPG_Account";
export const BLPG_HASH_ID                  = "_xBLPG_H_ID";
export const BLPG_MFA_TOKEN                = "_xBLPG_MFA_tkn";
export const BLPG_LOGIN_STATUS             = "_xBLPG_login_";
export const BLPG_COOKIE_ID                = "_cid_user";
export const BLPG_COOKIE_TIME              = "_cid_time";

export const KEY_DEVICE_ID     = "BLPG_DeviceID";
export const KEY_DEVICE_OS     = "BLPG_DeviceOS";
export const KEY_DEVICE_MODEL  = "BLPG_DeviceModel";
export const KEY_DEVICE_IP     = "BLPG_DeviceIP";
