const AYAKA_LOAN_ENDPOINT = "ayaka/v1/loans";
const AYAKA_PROFILE_INQUIRY = "ayaka/v1/profiles";
const AYAKA_PROFILE_FIND = "ayaka/v1/profiles/find/:id";
const AYAKA_PROFILE_LIST = "ayaka/v1/profiles";
const AYAKA_PROFILE_PHONE_NUMBER = "ayaka/v1/profiles/phone-number";
const AYAKA_PROFILE_PHONE_VERIFY = "ayaka/v1/profiles/phone-number/verify";
const AYAKA_PROFILE_UPDATE_CUSTOMER = "ayaka/v1/profiles/customer";
const AYAKA_EMPLOYEE = "ayaka/v1/employees";

export default {
  AYAKA_LOAN_ENDPOINT,
  AYAKA_PROFILE_INQUIRY,
  AYAKA_PROFILE_FIND,
  AYAKA_PROFILE_PHONE_NUMBER,
  AYAKA_PROFILE_PHONE_VERIFY,
  AYAKA_PROFILE_LIST,
  AYAKA_PROFILE_UPDATE_CUSTOMER,
  AYAKA_EMPLOYEE
};
