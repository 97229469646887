import {
  Authorization,
  Card,
  HeaderList,
  LoadLazy,
} from "@banklampung/components";
import React from "react";

const BasicList = ({
  body,
  resourceName,
  resourceUrl,
  addUrl,
  historyLink,
  resourceModal,
  resetAction,
  onClickBtn,
  createPermission,
  ...props
}) => {
  const refLink =
    addUrl || resourceUrl ? (addUrl ? addUrl : `${resourceUrl}/new`) : null;

  return (
    <Card
      headerSlot={
        <HeaderList
          createPermission={createPermission}
          historyLink={historyLink}
          addLink={refLink}
          resourceName={resourceName}
          toggleModal={resourceModal}
          resetAction={resetAction}
          onClick={onClickBtn}          
        />
      }
      {...props}
    >
      <LoadLazy>{body}</LoadLazy>
    </Card>
  );
};

export default BasicList;
