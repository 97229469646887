import {
  cilAccountLogout,
  cilAirplay,
  cilAperture,
  cilApple,
  cilBan,
  cilBank,
  cilBriefcase,
  cilCarAlt,
  cilCart,
  cilChartLine,
  cilChartPie,
  cilBarChart,
  cilCheckAlt,
  cilCheckCircle,
  cilChevronLeft,
  cilChevronRight,
  cilCreditCard,
  cilDataTransferDown,
  cilDollar,
  cilEnvelopeLetter,
  cilExternalLink,
  cilFastfood,
  cilFilter,
  cilFindInPage,
  cilHandPointUp,
  cilHome,
  cilHouse,
  cilLayers,
  cilLibrary,
  cilLockLocked,
  cilLockUnlocked,
  cilLoopCircular,
  cilMagnifyingGlass,
  cilMedicalCross,
  cilMobile,
  cilPaperPlane,
  cilPencil,
  cilPlus,
  cilPrint,
  cilQrCode,
  cilSave,
  cilShieldAlt,
  cilSpeedometer,
  cilSync,
  cilTransfer,
  cilTrash,
  cilTruck,
  cilThumbDown,
  cilThumbUp,
  cilUser,
  cilUserFollow,
  cilUserUnfollow,
  cilWindowRestore,
  cilXCircle,
  cilArrowThickRight,
  cilArrowCircleRight,
  cilArrowRight,
  cilArrowLeft,
  cilBike,
  cilMoney,
  cilSettings,
  cilFolder,
  cilWarning,
  cilList,
  cilClone,
  cilTask,
  cilFolderOpen,
  cilFax,
  cilBuilding,
  cilApplicationsSettings,
  cilPlaylistAdd,
  cilContact,
  cilPuzzle,
  cilAddressBook,
  cilBraille,
  cilAsteriskCircle
} from "@coreui/icons";
import { avatar } from "./avatar";
import { brandFull } from "./brand-full";
import { brandFullNegative } from "./brand-full-negative";
import { brandMini } from "./brand-mini";
import { brandMiniNegative } from "./brand-mini-negative";
import { blpgBpjstkLogo, blpgPayment, blpgSetting, blpgCustomerIndividual } from "./blpg"

export const icons = Object.assign(
  {},
  {
    avatar,
    brandMini,
    brandMiniNegative,
    brandFull,
    brandFullNegative,

    blpgCustomerIndividual,
    blpgBpjstkLogo, 
    blpgPayment, 
    blpgSetting
  },
  {
    cilBike,
    cilAirplay,
    cilFastfood,
    cilHouse,
    cilLibrary,
    cilHandPointUp,
    cilTruck,
    cilDataTransferDown,
    cilCheckAlt,
    cilTransfer,
    cilAccountLogout,
    cilAperture,
    cilApple,
    cilBriefcase,
    cilCarAlt,
    cilChartLine,
    cilChartPie,
    cilBarChart,
    cilChevronLeft,
    cilChevronRight,
    cilCreditCard,
    cilDollar,
    cilEnvelopeLetter,
    cilExternalLink,
    cilFilter,
    cilHome,
    cilLayers,
    cilLockLocked,
    cilLockUnlocked,
    cilPaperPlane,
    cilPencil,
    cilPlus,
    cilPrint,
    cilSpeedometer,
    cilSync,
    cilTrash,
    cilThumbDown,
    cilThumbUp,
    cilUser,
    cilSave,
    cilCart,
    cilFindInPage,
    cilBan,
    cilCheckCircle,
    cilQrCode,
    cilBank,
    cilXCircle,
    cilShieldAlt,
    cilWindowRestore,
    cilMagnifyingGlass,
    cilMedicalCross,
    cilLoopCircular,
    cilUserFollow,
    cilUserUnfollow,
    cilMobile,
    cilArrowThickRight,
    cilArrowCircleRight,
    cilArrowRight,
    cilArrowLeft,
    cilMoney,
    cilSettings,
    cilFolder,
    cilWarning,
    cilList,
    cilClone,
    cilTask,
    cilFolderOpen,
    cilFax,
    cilBuilding,
    cilApplicationsSettings,
    cilPlaylistAdd,
    cilContact,
    cilPuzzle,
    cilAddressBook,
    cilBraille,
    cilAsteriskCircle
  }
);
